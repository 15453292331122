import { z } from 'zod';
import { InterviewRequestStage } from '../../interview-request';

import {
  dateString,
  stringToBoolean,
  FilterOperations,
  makeSorter,
  ReportsQuerySchema,
  ReportResponse,
  Endpoint,
  Method,
} from '../../../infrastructure';
import { AppDetailSchema } from '../../application';

const ApplicantExpandedSchema = AppDetailSchema.pick({
  application_id: true,
  contract_status: true,
  name: true,
  email: true,
  availability: true,
  seniority: true,
  english_level: true,
  yoe: true,
  status: true,
  dt_applied: true,
  excitement_rate: true,
  country: true,
  country_code: true,
  city: true,
  state: true,
  timezone: true,
  timezone_number: true,
  timezone_short: true,
  latitude: true,
  longitude: true,
  expected_contract_type: true,
}).extend({
  is_gem: z.boolean(),
  is_stale: z.boolean(),
  is_aged: z.boolean(),
  status_name: z.string(),
  recruiter: z
    .object({
      person_id: z.string(),
      name: z.string(),
      email: z.string(),
    })
    .nullable(),
  author: z
    .object({
      person_id: z.string(),
      name: z.string(),
      email: z.string(),
    })
    .nullable(),
  job: z
    .object({
      job_id: z.string(),
      job_name: z.string(),
      description: z.string(),
    })
    .nullable(),
  current_stage: z.string().nullable(),
  dtcreate: dateString,
});

export const ApplicationSearchSchema = z.object({
  application_id: z.string(),
  job_id: z.string(),
  recruiter_id: z.string(),
  author_id: z.string().nullable(),
  cost: z.number().nullable(),
  usd_salary: z.number().nullable(),
  currency: z.string().nullable(),
  currency_cost: z.number().nullable(),
  currency_salary: z.number().nullable(),
  stage: z.number().nullable(),
  app_detail: ApplicantExpandedSchema,
  name: z.string(),
  match_score: z.number(),
  country_flag: z.string(),
});

export type ApplicationSearch = z.infer<typeof ApplicationSearchSchema>;

export const ApplicationSearchFiltersSchema = z
  .object({
    text_query: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    dt_created: z.record(z.enum([FilterOperations.BETWEEN]), z.array(dateString).length(2)),
    dt_updated: z.record(z.enum([FilterOperations.BETWEEN]), z.array(dateString).length(2)),
    status: z.record(z.enum([FilterOperations.IN, FilterOperations.NOT_IN]), z.string().array()),
    stage: z.record(
      z.enum([FilterOperations.IN, FilterOperations.NOT_IN]),
      z.coerce.number().array(),
    ),
    responsible_recruiter_id: z.record(z.enum([FilterOperations.IN]), z.string().array()),
    tech_recruiter_id: z.record(z.enum([FilterOperations.IN]), z.string().array()),
    job_ids: z.record(z.enum([FilterOperations.IN]), z.string().array()),
    skill_ids: z.record(z.enum([FilterOperations.IN]), z.coerce.number().array()),
    yoe_min: z.record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number()),
    yoe_max: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number()),
    english_level: z.record(z.enum([FilterOperations.IN]), z.coerce.number().array()),
    seniority: z.record(z.enum([FilterOperations.IN]), z.coerce.number().array()),
    availability: z.record(z.enum([FilterOperations.IN]), z.string().array()),
    excitement_rate: z.record(
      z.enum([FilterOperations.BETWEEN]),
      z.coerce.number().array().length(2),
    ),
    country_group: z.record(
      z.enum([FilterOperations.IN, FilterOperations.NOT_IN]),
      z.coerce.number().array(),
    ),
    country_codes: z.record(
      z.enum([FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().array(),
    ),
    visa_required: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean),
    executive_presence: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean),
    is_gem: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean),
    source: z.record(z.enum([FilterOperations.IN]), z.string().array()),
    salary_min: z.record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number()),
    salary_max: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number()),
    fast_decision: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean),
    dt_interviewed: z.record(z.enum([FilterOperations.BETWEEN]), z.array(dateString).length(2)),
    has_interview: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean),
    interview_status: z.record(z.enum([FilterOperations.IN]), z.string().array()),
    interview_stage: z.record(
      z.enum([FilterOperations.IN]),
      z.nativeEnum(InterviewRequestStage).array(),
    ),
    interviewer: z.record(z.enum([FilterOperations.IN]), z.string().array()),
    freshness: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number()),
    found_via: z.record(z.enum([FilterOperations.IN]), z.string().array()),
  })
  .strict()
  .partial();

export type ApplicationSearchFilters = z.infer<typeof ApplicationSearchFiltersSchema>;

export const ApplicationSearchSorterSchema = makeSorter(
  z.enum(['name', 'yoe', 'responsible_recruiter', 'status', 'salary', 'dt_created', 'job']),
);

export type ApplicationSearchSorter = z.infer<typeof ApplicationSearchSorterSchema>;

export const ApplicationSearchQuerySchema = ReportsQuerySchema.extend({
  filters: ApplicationSearchFiltersSchema.optional(),
  sorter: ApplicationSearchSorterSchema.optional(),
});

export type ApplicationSearchParams = {
  job_id: string;
};

export type ApplicationSearchQuery = z.infer<typeof ApplicationSearchQuerySchema>;

export type ApplicationSearchResponse = ReportResponse<ApplicationSearch>;

export type ApplicationSearchEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql/search/applications`,
  ApplicationSearchResponse,
  ApplicationSearchParams,
  ApplicationSearchQuery,
  {}
>;
