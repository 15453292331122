import {
  ApplicationQuickSearchEndpoint,
  ApplicationSearchEndpoints,
  SearchFiltersEndpoints,
} from '@ct-internal/api';
import APIRequest from '@src/shared/request';

import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import {
  APPLICATION_QUICK_SEARCH_KEY,
  APPLICATION_SEARCH_KEY,
  APPLICATION_SENIORITY_KEY,
  APPLICATION_ENGLISH_LEVEL_KEY,
  SEARCH_FILTERS_LIST,
  APPLICATION_SEARCH_SUMMARY_KEY,
} from './keys';

export const useApplicationQuickSearch = ({
  request,
  options,
}: {
  request: ApplicationQuickSearchEndpoint['request'];
  options?: UseQueryOptions<any>;
}): UseQueryResult<ApplicationQuickSearchEndpoint['response']> => {
  return useQuery({
    queryKey: [APPLICATION_QUICK_SEARCH_KEY, request],
    queryFn: () =>
      APIRequest(`/application-search/quick`, {
        method: 'POST',
        body: request,
        qsOptions: { arrayFormat: null },
        headers: {
          Accept: 'application/json',
        },
      }),
    ...options,
  });
};

export const useApplicationSearch = ({
  additionalQueryKeys = [],
  options,
  query,
}: {
  additionalQueryKeys?: any[];
  query?: ApplicationSearchEndpoints['search']['query'];
  options?: UseQueryOptions<ApplicationSearchEndpoints['search']['response']>;
}): UseQueryResult<ApplicationSearchEndpoints['search']['response']> => {
  return useQuery({
    queryKey: [APPLICATION_SEARCH_KEY, ...additionalQueryKeys],
    queryFn: () => {
      return APIRequest('/application-search', {
        method: 'GET',
        qs: query,
        qsOptions: { arrayFormat: null },
        headers: {
          Accept: 'application/json',
        },
      });
    },
    ...options,
  });
};
export const useApplicationSearchSummary = ({
  additionalQueryKeys = [],
  options,
  query,
}: {
  additionalQueryKeys?: any[];
  query?: ApplicationSearchEndpoints['summary']['query'];
  options?: UseQueryOptions<ApplicationSearchEndpoints['summary']['response']>;
}): UseQueryResult<ApplicationSearchEndpoints['summary']['response']> => {
  return useQuery({
    queryKey: [APPLICATION_SEARCH_SUMMARY_KEY, ...additionalQueryKeys],
    queryFn: () => {
      return APIRequest('/application-search/summary', {
        method: 'GET',
        qs: query,
        qsOptions: { arrayFormat: null },
        headers: {
          Accept: 'application/json',
        },
      });
    },
    ...options,
  });
};

export const downloadApplicationSearch = async (props: {
  query?: ApplicationSearchEndpoints['search']['query'];
}) => {
  const { query } = props;
  return APIRequest('/application-search/search.csv', {
    method: 'GET',
    qs: query,
    asRaw: true,
    headers: {
      Accept: 'text/csv',
    },
  });
};

export const useApplicationSeniority = ({
  options,
}: {
  options?: UseQueryOptions<ApplicationSearchEndpoints['listSeniority']['response']>;
}): UseQueryResult<ApplicationSearchEndpoints['listSeniority']['response']> => {
  return useQuery({
    queryKey: [APPLICATION_SENIORITY_KEY],
    queryFn: () => {
      return APIRequest('/application-search/seniority', {
        method: 'GET',
        qsOptions: { arrayFormat: null },
        headers: {
          Accept: 'application/json',
        },
      });
    },
    ...options,
  });
};

export const useApplicationEnglishLevel = ({
  options,
}: {
  options?: UseQueryOptions<ApplicationSearchEndpoints['listEnglishLevel']['response']>;
}): UseQueryResult<ApplicationSearchEndpoints['listEnglishLevel']['response']> => {
  return useQuery({
    queryKey: [APPLICATION_ENGLISH_LEVEL_KEY],
    queryFn: () => {
      return APIRequest('/application-search/english-level', {
        method: 'GET',
        qsOptions: { arrayFormat: null },
        headers: {
          Accept: 'application/json',
        },
      });
    },
    ...options,
  });
};

export const useSearchFilters = ({
  options,
}: {
  options?: UseQueryOptions<any>;
}): UseQueryResult<SearchFiltersEndpoints['listSearchFilters']['response']> => {
  return useQuery({
    queryKey: [SEARCH_FILTERS_LIST],
    queryFn: () =>
      APIRequest(`/application-search/filters`, {
        method: 'GET',
        qsOptions: { arrayFormat: null },
        headers: {
          Accept: 'application/json',
        },
      }),
    ...options,
  });
};
