import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { ApplicationSearchFiltersSchema } from './search';

export const ApplicationSearchSummarySchema = z.object({
  count_per_week: z.record(z.string(), z.number()),
  count_per_status: z.record(z.string(), z.number()),
});

export type ApplicationSearchSummary = z.infer<typeof ApplicationSearchSummarySchema>;

export const ApplicationSearchSummaryQuerySchema = z.object({
  filters: ApplicationSearchFiltersSchema.optional(),
});

export type ApplicationSearchSummaryQuery = z.infer<typeof ApplicationSearchSummaryQuerySchema>;

export type ApplicationSearchSummaryResponse = ApplicationSearchSummary;

export type ApplicationSearchSummaryEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql/application-search/summary`,
  ApplicationSearchSummaryResponse,
  undefined,
  ApplicationSearchSummaryQuery,
  {}
>;
